import React, { CSSProperties, ForwardRefRenderFunction, forwardRef, PropsWithChildren } from 'react'
import cx from 'classnames'

export interface ButtonProps {
  className?: string
  disabled?: boolean
  loading?: boolean
  onClick?: () => void
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void
  type?: 'button' | 'submit' | 'reset'
  style?: CSSProperties
}

const Button: ForwardRefRenderFunction<HTMLButtonElement, PropsWithChildren<ButtonProps>> = (
  { children, className, disabled, loading, onClick, onMouseDown, style, type = 'button' },
  ref
) => {
  return (
    <button
      className={cx(
        'tw-bg-moss tw-flex tw-items-center tw-justify-center tw-text-white tw-text-lg tw-py-3 tw-px-5 tw-rounded-full',
        className,
        {
          'hover:tw-bg-moss-lightish': !disabled && !loading,
        }
      )}
      disabled={disabled || loading}
      onClick={onClick}
      onMouseDown={onMouseDown}
      type={type}
      style={style}
      ref={ref}
    >
      {loading && (
        <svg className="tw-animate-spin tw--ml-1 tw-mr-3 tw-h-5 tw-w-5 tw-text-white" fill="none" viewBox="0 0 24 24">
          <circle className="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
          <path
            className="tw-opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
      {children}
    </button>
  )
}

export default forwardRef(Button)
